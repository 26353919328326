html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Safari fresh chat fix */
#fc_frame {
  display: inline-flex;
}

@font-face {
  font-family: 'AllianzNeo';
  src: url('./fonts/AllianzNeo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
